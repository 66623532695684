<template>
    <NuxtLink :to="to" class="blog-compact-item-container slick-slide slick-current slick-active" style="width: 290px;"
        data-slick-index="0" aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide00"
        aria-describedby="slick-slide-control00">
        <div class="blog-compact-item">
            <!-- <img :src="background" alt=""> -->
            <span class="blog-item-tag" v-if="tag">{{ tag }}</span>
            <div class="blog-compact-item-content">
                <ul class="blog-post-tags">
                    <li>{{ created_date }}</li>
                </ul>
                <h3>{{ title }}</h3>
                <p>{{ description }}</p>
            </div>
        </div>
    </NuxtLink>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
        },
        background: {
            type: String,
        },
        to: {
            type: String,
            default: '#'
        },
        tag: {
            type: String,
        },
        created_date: {
            type: String,
        }
    },
}
</script>

<style lang="scss" scoped></style>