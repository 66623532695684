<template>
    <!-- <div class="job-listing-company-logo"> -->
    <img :src="computedImgUrl" :width="width" @error="handleImageError" />
    <!-- @error="imageUrl = 'images/company-logo-05.png'" -->
    <!-- </div> -->
</template>

<script>
import { integer } from 'vuelidate/lib/validators';


export default {
    props: {
        logo: {
            type: String
        },
        width: {
            type: Number,
            required: true,
            default: function () {
                // Use Vuex state here
                return this.isMobile ? 100 : 50;
            }
        },
        anonymous: {
            type: Boolean,
            default: 0
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile;
        },
        computedImgUrl() {
            if (this.anonymous) {
                return '/images/company-logo-05.png';
            }
            return this.logo ? `/uploads/logo/${this.logo}` : '/images/company-logo-05.png';
        },
    },
    methods: {
        // getImgUrl: function () {
        //     if (!this.anonymous) {
        //         try {
        //             var image = require('~/static/uploads/logo/' + this.logo)
        //             return image;
        //         }
        //         catch (ex) {
        //             return '../../images/company-logo-05.png';

        //         }
        //     }
        //     else
        //         return '../../images/company-logo-05.png';

        //},
        handleImageError() {
            this.anonymous = true; // Fallback to the anonymous image
        }

    }
}
</script>
<style>
.job-listing-company-logo img {
    max-width: fit-content;
}
</style>