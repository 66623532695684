<template>
    <div class="
    section
    padding-top-65 padding-bottom-70
    full-width-carousel-fix
    ">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <!-- Section Headline -->
                    <Headline :title="head.title"> </Headline>
                </div>

                <div class="col-xl-12">
                    <div class="
                        blog-carousel
                        freelancers-container freelancers-grid-layout
                      ">
                        <CardCompact v-for="(item, i) in data" :key="i" v-bind="item"></CardCompact>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardCompact from '../base/CardCompact.vue';
import Headline from './Headline.vue';

export default {
    components: {
        CardCompact,
        Headline
    },
    props: {
        head: {
            type: Object,
            required: true
        },
        data: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped></style>