<template>
    <NuxtLink :to="'/item/item/' + itemId" class="job-listing with-apply-button">

        <!-- Job Listing Details -->
        <div class="job-listing-details">

            <!-- Logo -->
            <div class="job-listing-company-logo">
                <CompanyLogo :logo="logo" :width="50" :anonymous="anonymous" />
            </div>

            <!-- Details -->
            <div class="job-listing-description">
                <h3 class="job-listing-title"> {{ title }}</h3>

                <!-- Job Listing Footer -->
                <div class="job-listing-footer">
                    <ul>
                        <!-- <li><i class="icon-material-outline-business"></i>
                        </li> -->
                        <!-- <li>{{ category }}</li> -->
                        <li>
                            <i class="icon-feather-map-pin"></i>
                            <!-- <MapMarkerOutline /> --> {{ location }}
                        </li>
                        <li>
                            <!-- <BriefcaseVariantOutline /> -->
                            <i class="icon-feather-briefcase"></i>

                            {{ workingTime }}
                        </li>
                        <li>
                            <!-- <ClockOutline /> -->
                            <i class="icon-material-outline-access-time"></i>
                            {{ date | formatDate }}
                        </li>

                        <li v-show="minSalary"><i class="icon-material-outline-account-balance-wallet"></i> ${{ minSalary
                        }}-${{ maxSalary }}</li>
                        <li v-if="appliedDate" class="green">
                            <!-- <ClockOutline /> -->
                            <i class="icon-material-outline-access-time green"></i>
                            Applied on: {{ appliedDate | formatDate }}
                        </li>

                    </ul>
                </div>

            </div>

            <!-- Apply Button -->
            <!-- <span class="list-apply-button ripple-effect" @click.prevent="remove(itemId)" v-if="isFavourite"> Remove</span> -->
            <Bookmark :itemId="itemId" v-if="isFavourite" @remove="remove"></Bookmark>
            <span class="list-apply-button ripple-effect" v-else> Apply Now</span>
        </div>
    </NuxtLink>
</template>

<script>

import CompanyLogo from "./CompanyLogo.vue";
import Bookmark from "~/components/jobs/Bookmark";

export default {
    components: {
        CompanyLogo,
        Bookmark
    },
    data: function () {
        return {
            baseUrl: process.env.baseUrl
        }
    },

    props: {
        userId: {
            type: Number,
            required: true
        },
        itemId:
        {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true,
        },

        category: {
            type: String,
            required: true,
        },
        location: {
            type: String
        },
        workingTime: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        minSalary: {
            type: Number,
            required: true,
            default: 0
        },
        maxSalary: {
            type: Number,
            required: true,
            deafult: 0
        },
        logo: {
            type: String
        },

        anonymous: {
            type: Boolean
        },
        appliedDate: {
            type: String
        },
        isFavourite: {
            type: Boolean
        }

    },
    methods: {
        remove() {
            console.log("removed in list item job");

            this.$emit('removed', this.itemId);
        }
    }
}
</script>

<style lang="scss" scoped>
span.icon {
    position: relative;
    top: 5px;
}

.compact-list-layout .job-listing .job-listing-footer {
    padding-bottom: 15px;
}

.job-listing .job-listing-footer {
    position: relative;
}
</style>