<template>
    <div class="section-headline margin-top-0" :class="{ 'margin-bottom-35': !isMobile }">
        <slot>
            <h3 v-if="title">{{ title }}</h3>
        </slot>
        <NuxtLink v-if="link" :to="link.to" class="headline-link">{{ link.name }}</NuxtLink>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            isMobile: "isMobile"
        })
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        link: {
            type: Object,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped></style>