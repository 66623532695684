<template>
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="intro-banner" style="background-image: url(images/home-4.jpg);">
        <div class="background-image-container" style="background-image: url(images/home-4.jpg);"></div>
        <!-- <div class="intro-banner" data-background-image="images/home-4.jpg"> -->

        <div class="container">

            <!-- Intro Headline -->
            <div class="row">
                <div class="col-md-12">

                    <Heading1>
                        Macher Jobs - The largest Jewish <br>Jobs website
                    </Heading1>

                    <div class="banner-headline">
                        <p>Find your dream job among thousands of available positions within the Jewish community in
                            NYC, Brooklyn, Boro Park, Five Towns, Crown Heights, Manhattan, Monsey, Lakewood,
                            Teaneck, and more!
                        </p>

                    </div>
                </div>
            </div>
            <!-- Search Bar -->
            <SearchBar></SearchBar>
            <!-- Stats -->
            <div class="row">
                <div class="col-md-12">
                    <ul class="intro-stats margin-top-45 hide-under-992px">
                        <li>
                            <strong class="counter">{{ counters.totalJobs | formatNumber }}</strong>
                            <span>Jobs Listings</span>
                        </li>
                        <li>
                            <strong class="counter">{{ counters.totalViews | formatNumber }}</strong>
                            <span>Total Job Views</span>
                        </li>
                        <li>
                            <strong class="counter">{{ counters.totalEmployers | formatNumber }}</strong>
                            <span>Active Employers</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="background-image-container" style="background-image: url('images/home-bg.jpg';);">
        </div>
    </div>
</template>

<script>
import Heading1 from '../base/Heading1.vue';
import SearchBar from '@/components/homepage/SearchBar.vue';
import { mapState } from "vuex";

export default {
    components: {
        Heading1,
        SearchBar
    },
    data() {
        return { baseUrl: process.env.baseUrl, }
    },
    computed: {
        ...mapState("base", {
            counters: (state) => state.counters
        })
    },

}
</script>

<style lang="scss" scoped></style>