<template>
  <div>
    <!-- Intro Banner
================================================== -->
    <Intro></Intro>

    <!-- Content
================================================== -->
    <!-- Category Boxes -->
    <Category></Category>
    <!-- Category Boxes / End -->

    <!-- Features Jobs -->
    <Jobs :featuredJobs="featuredJobs"></Jobs>
    <!-- Featured Jobs / End -->

    <!-- Featured Section -->
    <!-- <Featured :head="{ title: 'Latest Real Estate Ads' }" :data="featuredRealEstateAds"></Featured>
    <Featured class="gray" :head="{ title: 'Featured Business Ads' }" :data="featuredRealEstateAds"></Featured>
    <Featured :head="{ title: 'Featured Products' }" :data="featuredRealEstateAds"></Featured> -->
    <!-- Featured Section / End -->

    <!-- Companies Logo -->
    <Companies></Companies>
  </div>
</template>

<script>
import Category from '../components/homepage/Category.vue';
import Featured from '../components/homepage/Featured.vue';
import Intro from '../components/homepage/Intro.vue';
import Jobs from '../components/homepage/Jobs.vue';
import Companies from '../components/homepage/Companies.vue';
import { mapState } from "vuex";

//import jQuery from "jquery";
export default {
  name: "Home",
  layout: "default",
  data() {
    return {
      head: {
        title: "Macher Jobs - The largest Jewish Jobs website",
        description: "Explore Jewish job opportunities in Brooklyn, Boro Park, Lakewood, Monsey, the Five Towns, and beyond at Macher Jobs. Discover diverse listings across the USA. Connect with top employers, unlock your career potential, and thrive within the supportive Jewish professional network."
      },
    }
  },
  head() {
    const { path = "" } = this.$route;

    const metaTitle = this.head.title;
    const metaDescription = this.head.description
    const canonical = process.env.baseUrl + path;

    return {
      title: metaTitle,
      link: [{ rel: "canonical", href: canonical }],
      meta: this.createMetaTags({
        description: metaDescription,
        title: metaTitle,
        canonical,
        socialTitle: metaTitle,
        socialDescription: metaDescription,
        siteName: "Macher Jobs",
        twitter: "@FrumJewishJobs",
      }),
    };
  },
  computed: {
    ...mapState("base", {
      featuredJobs: (state) => state.featuredJobs,
      isHomePageListingsLoaded: (state) => state.isHomePageListingsLoaded
    })
  },

  methods: {

  },
  async asyncData({ app, params, store, $sentry }) {
    if (process.server) {
      try {
        await store.dispatch("base/getHomePageListings");
      }
      catch (error) {
        $sentry.captureException(error)
      }
    }
  },
  async mounted() {
    if (process.client && !this.isHomePageListingsLoaded) {
      try {
        await this.$store.dispatch("base/getHomePageListings");
      } catch (error) {
        this.$handleExceptionWithSentry(error);
      }
    }
  },
  components: {
    Intro,
    Category,
    Jobs,
    Featured,
    Companies
  }
};
</script>
