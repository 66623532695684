<template>
	<div class="section margin-top-65">
		<div class="container">
			<div class="row">
				<div class="col-xl-12">

					<div class="section-headline centered margin-bottom-15">
						<h3>Popular Job Categories</h3>
					</div>

					<!-- Category Boxes Container -->
					<div class="categories-container">

						<!-- Category Box -->
						<CategoryBox v-for="(category, i) in popularCategories" :key="i" v-bind="category">
							<template v-slot:icon>
								<component :size="48" :is="getIcon(category)"> </component>
							</template>
						</CategoryBox>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CategoryBox from '@/components/base/CategoryBox.vue';
// Material icons
import Laptop from "vue-material-design-icons/Laptop.vue";
import Headset from "vue-material-design-icons/Headset.vue";
import BriefcaseOutline from "vue-material-design-icons/BriefcaseOutline.vue";
import SchoolOutline from "vue-material-design-icons/SchoolOutline.vue";
import MedicalBag from "vue-material-design-icons/MedicalBag.vue";//12
import Finance from "vue-material-design-icons/Finance.vue"; //3
import HumanQueue from "vue-material-design-icons/HumanQueue.vue"; //15
import HomeCity from "vue-material-design-icons/HomeCity.vue"; //37


import { mapState } from "vuex";

export default {
	components: {
		CategoryBox,
		Laptop,
		Headset,
		BriefcaseOutline,
		SchoolOutline,
		MedicalBag,
		Finance,
		HumanQueue,
		HomeCity
	},
	data: function () {
		return {
			icons: [{
				Icon: "Laptop", id: 16
			},
			{ Icon: "Headset", id: 34 }
				, { Icon: "BriefcaseOutline", id: 19 }
				, { Icon: "SchoolOutline", id: 14 }
				, { Icon: "MedicalBag", id: 12 }
				, { Icon: "Finance", id: 3 }
				, { Icon: "HumanQueue", id: 15 }
				, { Icon: "HomeCity", id: 37 }]
		}
	},
	computed: {
		...mapState("base", {
			popularCategories: (state) => state.popularCategories
		})
	},
	methods:
	{
		getIcon: function (category) {

			return this.icons.filter((x) => x.id == category.id)[0].Icon;
		}
	}
}
</script>

<style lang="scss" scoped></style>