<template>
    <span class="bookmark-icon" :class="{ bookmarked: isChecked }" @click.prevent="toggleBookmark()"></span>
</template>
<script>
export default {
    props: {
        itemId: 0
    },

    data: function () {
        return {
            isChecked: false
        }

    },
    mounted() {
        var bookmarks = this.getBookmarks();
        if (bookmarks.indexOf(this.itemId.toString()) > -1)
            this.isChecked = true;

    },
    methods: {
        toggleBookmark() {
            //when removed in dashbaord, fire an event to delete the row
            this.$emit('remove', this.itemId);
            console.log("removed in bookmark");
            this.isChecked = !this.isChecked;
            var bookmarks = this.getBookmarks();
            if (this.isChecked) {
                bookmarks.push(this.itemId);
                localStorage.setItem("bookmarks", bookmarks);
            }
            else {
                var newBookmarks = bookmarks.filter(item => parseInt(item) !== this.itemId);
                localStorage.setItem("bookmarks", newBookmarks);
            }

        },
        getBookmarks() {
            var bookmarks = [];
            var items = localStorage.getItem("bookmarks");
            if (items)
                bookmarks = items.split(",");
            return bookmarks;

        }
    }
}
</script>