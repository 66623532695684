<template>
    <div :class="sectionClasses">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <!-- Section Headline -->
                    <div class="section-headline margin-top-0 margin-bottom-35">
                        <h3>{{ title }}</h3>
                        <NuxtLink to="/all" class="headline-link">Browse All Jobs</NuxtLink>
                    </div>

                    <!-- Jobs Container -->
                    <div class="listings-container compact-list-layout margin-top-35">
                        <!-- Job Listing -->

                        <JobListItem v-for="job in featuredJobs" :isFavourite="title == 'Favorites'"
                            @removed="handleRemoved" :key="job.itemId" v-bind="job">

                        </JobListItem>
                    </div>
                    <!-- Jobs Container / End -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JobListItem from '@/components/base/ListItemJob.vue';
export default {
    components: {
        JobListItem
    },
    props: {
        featuredJobs: {
            type: Array,
            required: true,
            default: () => [],
        },
        title: {
            type: String,
            required: false,
            default: "Featured Jobs",
        },
    },
    methods:
    {
        //this will remove favourites item in dashboard
        handleRemoved(removedItemId) {
            this.$emit('removed', removedItemId);

        }
    },
    computed: {
        sectionClasses() {
            if (this.title !== "Featured Jobs")
                return 'margin-bottom-50';

            else
                return 'section gray margin-top-45 padding-top-65 padding-bottom-75';

        }
    }


    // computed: {
    //     ...mapState("base", {
    //         featuredJobs: (state) => state.featuredJobs
    //     })
    // },
}
</script>

<style lang="scss" scoped></style>