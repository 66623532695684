<template>
    <div>
        <div class="section border-top padding-top-45 padding-bottom-45">
            <!-- Logo Carousel -->
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <!-- Section Headline -->
                        <Headline :title="head.title"> </Headline>
                    </div>
                    <div class="col-xl-12">
                        <!-- Carousel -->
                        <div class="col-md-12">
                            <div class="logo-carousel freelancers-container freelancers-grid-layout">
                                <div v-for="(item, i) in companies"
                                    class="carousel-item slick-slide slick-current slick-active" :data-slick-index="i"
                                    aria-hidden="true" style="width: 230px;" tabindex="-1">
                                    <a :href="item.link" target="_blank" tabindex="-1"><img
                                            :src="`images/companies-logo/${item.logo}`" alt=""></a>
                                </div>

                            </div>
                        </div>
                        <!-- Carousel / End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Headline from './Headline.vue';

export default {
    components: {
        Headline
    },
    mounted() {
        if (process.browser) {
            initialCarousel();
        }
    },
    data() {
        return {
            head: {
                title: "Companies We Work With"
            },
            companies: [
                {
                    logo: "LKWD-Jobs-logo.png", link: "https://lkwdjobs.com/"
                },
                {
                    logo: "northwestern.png", link: "https://www.northwesternmutual.com/"
                },
                {
                    logo: "lbaps.png", link: "https://www.lbaps.com/"
                },
                {
                    logo: "anchor.png", link: "https://www.anchorhc.org/"
                },
                {
                    logo: "hamaspikcare-logo.png", link: "https://hamaspikhomecare.org/"
                }, {
                    logo: "hamaspikkings-logo.png", link: "http://hamaspikkings.org/"
                },
                {
                    logo: "jgsi.png", link: "https://www.thejgsi.org/"
                },
                {
                    logo: "goldens.png", link: "https://www.goldenstepsaba.com/"

                },
                {
                    logo: "ab-hires.png", link: "https://ab-hires.com/"

                },
                {
                    logo: "ezra-logo.webp", link: "https://ezramedical.org/"
                },
                {
                    logo: "churchillliving-logo.png", link: "https://www.churchillliving.com/"
                },
                {
                    logo: "snys-logo.png", link: "https://caresnys.org/"
                },
                {
                    logo: "broad-logo.png", link: "https://broadmanagementgroup.com/"
                },
                {
                    logo: "encore-logo.png", link: "https://encoresupport.org/"
                },
                {
                    logo: "hasc-logo.png", link: "https://www.hasccenter.org/"
                },
                {
                    logo: "hcs-logo.png", link: "http://hcsny.org/"
                },
                {
                    logo: "hebrew-academy-logo.png", link: "https://www.halb.org/"
                },
                {
                    logo: "ou.png", link: "https://www.ou.org/"
                },
                // {
                //     logo: "lbasbs-logo.png", link: "https://lbaps.com/"
                // },

                {
                    logo: "makor-logo.png", link: "https://makornetwork.org/"
                },
                {
                    logo: "newyorklife-logo.png", link: "https://www.newyorklife.com/"
                },
                {
                    logo: "northwestern-logo.png", link: "https://www.northwesternmutual.com/"
                },
                {
                    logo: "interborough.png", link: "https://www.interborough.org/"
                },
                {
                    logo: "hiresolutionsny.png", link: "https://hiresolutionsny.com/"
                },

                {
                    logo: "jccgci.png", link: "https://www.jccgci.org/"
                },

                // "Projx-logo.png",
                {
                    logo: "rothandCo-logo.png", link: "http://rothcocpa.com/"
                },

                {
                    logo: "TheJewishBoard-logo.png", link: "http://jbfcs.org/"
                },
                {
                    logo: "TriumphBehaviourSupport-logo.png", link: "https://www.triumphaba.com/"
                },
                {
                    logo: "Yeled-logo.png", link: "https://yeled.org/"
                },
                {
                    logo: "mbyacademy-logo.webp", link: "https://www.mbyacademy.org/"
                }
            ]
        }
    },
}
</script>

<style lang="scss" scoped></style>