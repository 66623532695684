<template>
    <NuxtLink :to="url" class="category-box">
        <div class="category-box-icon">
            <slot name="icon">

            </slot>
            <!-- <i class="icon-line-awesome-file-code-o"></i> -->
        </div>
        <div class="category-box-counter">{{ count }}</div>
        <div class="category-box-content">
            <h3>{{ name }}</h3>
            <p>{{ cutDescription(description) }}</p>
        </div>
    </NuxtLink>
</template>

<script>
export default {

    props: {
        name: {
            type: String,
            required: true
        },
        description: {
            type: String
        },
        icon: {
            type: String
        },
        url: {
            type: String,
            default: '#'
        },
        count: {
            type: [String, Number]
        }
    },
    data: function () {
        return {
            baseUrl: process.env.baseUrl,
        }
    },

    methods: {
        cutDescription: function (description) {
            var array = description.split(",");
            var shorDescription = "";
            for (var i = 0; i < 4 && i < array.length; i++)
                shorDescription += array[i].trim() + ", ";
            shorDescription = shorDescription.substring(0, shorDescription.length - 1);
            return shorDescription;
        },
        getIconName: function (categoryId) {

        }
    }

}
</script>

<style lang="scss" scoped></style>